import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class CommonServiceModule {
  domain = "https://webphp.sh-grp.com/"; // 线上
  loginfo = window.localStorage.getItem('loginfo');
  token = ''; 

	constructor() {
	    console.log(this.loginfo);
		if(this.loginfo == '' || this.loginfo == null){
			return ;
		}else{
		    let obj = JSON.parse((this.loginfo as string));
		    if('token' in obj){
		        this.token = JSON.parse((this.loginfo as string)).token;
		    }
			
		}
	}
}
